import { getAuthProfile, clearCookie } from "@/services/AuthService";
import Api from "@/services/Api";

export const authProfile = {
  namespaced: true,
  state: {
    credentialId: null,
    secretKey: null,
    username: null,
    firstname: null,
    lastname: null,
    email: null,
    institution: null,
    userIdentifier: null,
    inLoading: false,
    isTairCurator: null,
    affiliations: null,
    orcidId: null,
  },
  mutations: {
    // A generic mutation to update state properties
    UPDATE_STATE(state, data) {
      Object.keys(data).forEach((key) => {
        if (state.hasOwnProperty(key)) {
          state[key] = data[key];
        }
      });
    },

    RESET_STATE(state) {
      // Reset each property to its initial state
      state.credentialId = null;
      state.secretKey = null;
      state.username = null;
      state.firstname = null;
      state.lastname = null;
      state.email = null;
      state.institution = null;
      state.userIdentifier = null;
      state.inLoading = false;
      state.affiliations = null;
      state.orcidId = null;
    },

    SET_IN_LOADING(state) {
      state.inLoading = true;
    },

    RESET_IN_LOADING(state) {
      state.inLoading = false;
    },
  },
  actions: {
    async loadAuthProfile({ commit }) {
      // avoid duplicated loading
      if (this.isLoggedIn || this.isAuthProfileLoading) return true;
      commit("SET_IN_LOADING");
      const userProfile = await getAuthProfile();
      commit("RESET_IN_LOADING");
      if (userProfile && userProfile.username) {
        // make sure the user profile is not empty
        commit("UPDATE_STATE", userProfile);
        return true; // return true when loading the auth profile succeed
      }
      return false; // return false when loading the auth profile failed
    },

    async invalidate({ commit }) {
      commit("RESET_STATE");
      await clearCookie(); // clear the cookie
    },

    async getOrcidLoginUrl() {
      try {
        const response = await Api().get("auth/orcidloginlink");
        return response.data.url
      } catch (e) {
        console.error('Error getting Orcid login URL', e)
      }
    },
  },
  getters: {
    getCredentials: (state) => {
      return {
        credentialId: state.credentialId,
        secretKey: state.secretKey,
      };
    },
    isAuthProfileLoading: (state) => {
      return state.isLoading;
    },
    getIsTairCurator: (state) => {
      return state.isTairCurator;
    },
    isLoggedIn: (state) => {
      return state.credentialId && state.secretKey;
    },
    getCommunityId: (state) => {
      return state.userIdentifier;
    },
    getPartyId: (state) => {
      return state.credentialId
    },
    getFirstName: (state) => {
      return state.firstname;
    },
    getLastName: (state) => {
      return state.lastname;
    },
    getAffiliations: (state) => {
      return state.affiliations;
    },
    getOrcidId: (state) => {
      return state.orcidId;
    },
    getLoginUrl() {
      return (
        process.env.VUE_APP_LOGIN_BASE_URL +
        "/#/contentaccess/login?partnerId=tair&redirect=" +
        process.env.VUE_APP_UI_BASE_URL +
        "/auth/login"
      );
    },
  },
};
