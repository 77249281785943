<template>
	<div class="usage-tracker">
	  <h3 class="tracker-title">Usage Tracker</h3>
	  <div v-if="trackerData != null" class="tracker-content">
		<div class="units-bar">
		  <div class="units-label">
			<span>Remaining Units</span>
			<span class="units-value">{{ trackerData.remaining_units }} / {{ trackerData.total_units }}</span>
		  </div>
		  <b-progress :value="usagePercentage" :variant="progressVariant"></b-progress>
		</div>
		<div class="tracker-details">
		  <div v-if="formattedExpiryDate" class="detail-item">
			<i class="fas fa-calendar-alt"></i>
			<span>Expiry Date: {{ formattedExpiryDate }}</span>
		  </div>
		  <b-button variant="primary" class="mr-2" :href="bucketPurchaseLink" target="_blank">Add Bucket</b-button>
		  <div v-if="trackerData.bucketsBought" class="detail-item">
			<i class="fas fa-box"></i>
			<span>Buckets Bought: {{ trackerData.bucketsBought }}</span>
		  </div>
		</div>
	  </div>
	  <div v-else class="tracker-content">
		<div v-if="!isLoading" class="tracker-content">
			<span>No Usage Units Available</span>
			<b-button variant="primary" class="mr-2" :href="bucketPurchaseLink" target="_blank">Add Usage Units</b-button>
		</div>
		<div v-else>
			<span>Loading Usage Tracker...</span>
		</div>
	  </div>
	  <div class="mt-2">
		<b-button variant="secondary" @click="showActivation = !showActivation">
			{{ showActivation ? 'Hide' : 'Enter Activation Code' }}
		</b-button>
		<!-- New inline activation section -->
		<b-collapse id="activation-section" v-model="showActivation" class="mt-3">
			<b-card>
			<h4>Redeem your activation code</h4>
			<b-form @submit.prevent="activateSubscription">
				<b-form-group>
				<b-form-input
					id="activation-code"
					v-model="activationCode"
					:state="activationCodeState"
					required
					placeholder="Enter your activation code"
				></b-form-input>
				</b-form-group>
				<b-button type="submit" variant="primary" :disabled="isActivating">
				<b-spinner small v-if="isActivating"></b-spinner>
				{{ isActivating ? 'Activating...' : 'Activate' }}
				</b-button>
			</b-form>
			<b-alert v-if="activationError" show variant="danger" class="mt-3">
				{{ activationError }}
			</b-alert>
			<b-alert v-if="activationSuccess" show variant="primary" class="mt-3">
				{{ activationSuccess }}
			</b-alert>
			</b-card>
        </b-collapse>
	  </div>
	</div>
  </template>
  
  <script>
  import Api from "@/services/Api";
  export default {
	name: 'UsageTracker',
	props: {
	  trackerData: {
		type: [Object, null],
    	default: null
	  },
	  bucketPurchaseLink: {
		type: String,
		default: null
	  },
	  partyId: {
		type: String,
		default: null
	  },
	  onFetchTrackerData: {
		type: Function,
		required: true
	  },
	  isLoading: {
		type: Boolean,
		default: false
	  }
	},
	computed: {
	  usagePercentage() {
		if(this.trackerData.remaining_units == this.trackerData.total_units) return 100
		return (this.trackerData.remaining_units / this.trackerData.total_units) * 100;
	  },
	  progressVariant() {
		if (this.usagePercentage > 70) return 'success';
		if (this.usagePercentage > 30) return 'warning';
		return 'danger';
	  },
	  formattedExpiryDate() {
		if(!this.trackerData.expiry_date) return null;
		return new Date(this.trackerData.expiry_date).toLocaleDateString();
	  },
	  activationCodeState() {
		return this.activationCode.length > 0 ? null : false;
		},
	},
	data() {
		return {
			activationCode: "",
			showActivation: false,
			isActivating: false,
			activationError: null,
			activationSuccess: null
		}
	},
	methods: {
		async activateSubscription() {
			if (!this.activationCode) {
				this.activationError = 'Please enter a valid activation code.';
				return;
			}

			this.isActivating = true;
			this.activationError = null;

			try {
				if(!this.partyId) {
					this.activationError = 'Error during activation.';
					console.error("partyId is not set properly")
				}
				const response = await Api().post('/subscription/activate/bucket', { activationCode: this.activationCode, partyId: this.partyId });
				if (response.status && response.status == 200) {
				this.activationSuccess = "Activation Code applied successfully!"
				this.isActivating = false;
				this.activationError = "";
				this.activationCode = "";
				await this.onFetchTrackerData();
				} else {
				throw new Error(response.data.message || 'Activation failed');
				}
			} catch (error) {
				// console.log(error);
				if (error.response) {
				this.activationError = error.response.data.message || 'An error occurred during activation.';
				} else {
				this.activationError = error.message || 'An error occurred while activating the subscription.';
				}
			} finally {
				this.isActivating = false;
			}
		},
	}
  }
  </script>
  
  <style scoped>
  .usage-tracker {
	background-color: #f8f9fa;
	border-radius: 8px;
	padding: 1.5rem;
	box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .tracker-title {
	font-size: 1.5rem;
	margin-bottom: 1rem;
	color: var(--primary-color);
  }
  
  .tracker-content {
	display: flex;
	flex-direction: column;
	gap: 1rem;
  }
  
  .units-bar {
	background-color: white;
	padding: 1rem;
	border-radius: 8px;
	box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }
  
  .units-label {
	display: flex;
	justify-content: space-between;
	margin-bottom: 0.5rem;
	font-weight: bold;
  }
  
  .units-value {
	color: var(--primary-color);
  }
  
  .tracker-details {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 1rem;
  }
  
  .detail-item {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	background-color: white;
	padding: 0.75rem 1rem;
	border-radius: 8px;
	box-shadow: 0 1px 3px rgba(0,0,0,0.1);
	flex: 1;
	min-width: 200px;
  }
  
  .detail-item i {
	color: var(--primary-color);
  }
  
  @media (max-width: 768px) {
	.tracker-details {
	  flex-direction: column;
	}
  }
  </style>