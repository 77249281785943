<template>
  <div class="my-profile">
    <b-container v-if="!is_not_login">
      <b-row>
        <b-col cols="12" md="8">
          <b-card class="mb-4">
            <h2 class="card-title">Welcome, {{ personData.FIRST_NAME }} {{ personData.LAST_NAME }}</h2>
            <p>Your ORCID ID: {{ orcidFullId }}</p>
            <p class="font-weight-bold">{{ subscriber_info }}</p>
            <p class="font-weight-bold">{{ subscriber_message }}</p>
          </b-card>

          <usage-tracker v-if="!isSubscribed" :tracker-data="trackerData" :bucketPurchaseLink="bucketPurchaseLink" :partyId="partyId" :onFetchTrackerData="fetchTrackerData" :isLoading="isTrackerDataLoading" class="mb-4" />

          <b-card class="mb-4">
            <h3 class="card-title">Address</h3>
            <div v-html="getAddress()"></div>
          </b-card>

          <b-card>
            <h3 class="card-title">Affiliated Organizations</h3>
            <b-list-group v-if="personData.AFFILIATIONS">
              <b-list-group-item v-for="(o, index) in personData.AFFILIATIONS" :key="index">
                <b-link :href="`/organization?key=${o.community_id}`">{{ o.name }}</b-link>
              </b-list-group-item>
            </b-list-group>
            <span v-else>No affiliations found</span>
          </b-card>
        </b-col>

        <b-col cols="12" md="4">
          <b-card class="mb-4">
            <h3 class="card-title">Quick Links</h3>
            <b-list-group>
              <b-list-group-item :href="`/person?key=${communityId}`">View my public profile</b-list-group-item>
              <b-list-group-item :href="`/edit/person?key=${communityId}`">Update Profile</b-list-group-item>
            </b-list-group>
          </b-card>

          <b-card class="mb-4" v-if="isSymbolRegEnabled">
            <h3 class="card-title">Contribute Data to TAIR</h3>
            <b-list-group>
              <b-list-group-item href="/submit/gene_class_symbol_registration">Submit New Gene Class Symbol</b-list-group-item>
              <b-list-group-item href="https://goat.phoenixbioinformatics.org">Online Submission for Authors and Others</b-list-group-item>
            </b-list-group>
          </b-card>

          <!-- Conditional rendering for TAIR Curator section -->
          <b-card v-if="personData.IS_TAIR_CURATOR === 'T'" class="mb-4">
            <h3 class="card-title">TAIR Curator Tools</h3>
            <b-list-group>
              <b-list-group-item href="https://arabidopsis.org/servlets/processor?type=genesymbol&update_action=view_symbol&from=curator&status=new" target="_blank">
                Review Gene Class Symbols
              </b-list-group-item>
              <b-list-group-item href="/browse/gene_symbols">Browse Gene Class Symbols</b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <b-alert v-else show variant="warning">You are not logged in. Please login to view this page.</b-alert>
  </div>
</template>

<script>
import Api from "@/services/Api";
import { mapGetters } from "vuex";
import { getSubscriptionStatus } from "@/services/SubscriptionService";
import store from "@/store/store";
import UsageTracker from "../components/UsageTracker.vue";

export default {
  name: "MyProfile",
  components: {
    UsageTracker
  },
  data() {
    return {
      trackerData: null,
      personData: {},
      is_not_login: false,
      communityId: null,
      subscriber_message: "",
      isSubscribed: false,
      isSymbolRegEnabled: false,
      orcidFullId: "",
      partyId: "",
      isTrackerDataLoading: false
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    subscriber_info() {
      if (this.isSubscribed && this.isSymbolRegEnabled) {
        return "TAIR Subscriber Account plus Gene Class Symbol registration - includes full access to TAIR pages and Gene Class Symbol registration";
      } else if (this.isSubscribed && !this.isSymbolRegEnabled) {
        return "TAIR Subscriber Account - includes full access to TAIR pages without Gene Class Symbol registration";
      } else if (!this.isSubscribed && this.isSymbolRegEnabled) {
        return "Basic TAIR Account plus Gene Class Symbol registration - includes metered access to TAIR pages and Gene Class Symbol registration";
      } else {
        return "Basic TAIR Account - includes metered access to TAIR pages without Gene Class Symbol registration";
      }
    },
    subscribeLink() {
      return `${process.env.VUE_APP_LOGIN_BASE_URL}/#/contentaccess/subscription?partnerId=tair&redirect=${process.env.VUE_APP_UI_BASE_URL}`;
    },
    bucketPurchaseLink() {
      return `${process.env.VUE_APP_LOGIN_BASE_URL}/#/contentaccess/subscription?partnerId=tair&redirect=${process.env.VUE_APP_UI_BASE_URL}`;
    },
  },
  methods: {
    ...mapGetters({
      getCommunityId: "authProfile/getCommunityId",
      getOrcidId: "authProfile/getOrcidId",
      getPartyId: "authProfile/getPartyId"
    }),
    getAddress() {
      let address = "";
      if (this.personData.ADDRESS) {
        address += this.personData.ADDRESS + "<br />";
      }
      if (this.personData.CITY) {
        address += this.personData.CITY + ", ";
      }
      if (this.personData.REGION) {
        address += this.personData.REGION + " - ";
      }
      if (this.personData.POSTAL_CODE) {
        address += this.personData.POSTAL_CODE + "<br />";
      }
      if (this.personData.COUNTRY) {
        address += this.personData.COUNTRY;
      }

      if(address == "") {
        address = "No address found"
      }
      return address;
    },
    async init() {
      const loaded = await store.dispatch("authProfile/loadAuthProfile");
      if(!loaded) {
        this.is_not_login = true; 
        return;
      }
      let communityId = this.getCommunityId();
      if (!communityId) {
        this.is_not_login = true;
        return;
      }
      this.communityId = communityId;
      this.orcidFullId = this.getOrcidId();
      this.partyId = this.getPartyId();
      await this.fetchTrackerData();
      await this.fetchPersonData();
      await this.updateSubscriptionStatus();
      this.isTrackerDataLoading = false;
    },
    async updateSubscriptionStatus() {
      let responseSub = await getSubscriptionStatus();
      if (responseSub.subscribed) {
        this.isSubscribed = true;
        let expDate = new Date(responseSub.expDate);
        expDate = expDate.toISOString().split("T")[0];
        this.subscriber_message = `You're subscribed until ${expDate}.`;
      } else {
        this.subscriber_message = "";
        this.isSubscribed = false;
      }
    },

    async fetchPersonData() {
      try {
        const response = await Api().get('/user/person-details', { params: {party_id: this.partyId} });
        if(response.data.message) {
          this.personData = null;
        } else {
          this.personData = response.data;
          this.isSymbolRegEnabled = this.personData.REGISTERING_FOR_ABRC === "T";
        }
      } catch (error) {
        console.error('Error fetching person data:', error);
      }
    },
    async fetchTrackerData() {
      try {
        this.isTrackerDataLoading = true;
        const response = await Api().get('/subscription/bucket/usage', { params: {party_id: this.partyId} });
        if(response.data.message) {
          this.trackerData = null;
        } else {
          this.trackerData = response.data;
        }
        this.isTrackerDataLoading = false;
      } catch (error) {
        console.error('Error fetching tracker data:', error);
        this.isTrackerDataLoading = false;
      }
    }
  },
};
</script>

<style scoped>
.my-profile {
  --primary-color: #007bff;
  --secondary-color: #6c757d;
  --background-color: #f8f9fa;
  --text-color: #333;
}

.my-profile {
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 2rem 0;
}

.card-title {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-secondary {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

@media (max-width: 768px) {
  .my-profile {
    padding: 1rem 0;
  }
}
</style>